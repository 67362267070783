import React, { useState, useEffect } from 'react';
import './Vouchers.css';
import '../cart/Carro.css';

export const Vouchers = () => {
  // Al iniciar el componente, intenta recuperar el valor guardado en localStorage
  const [codigoComprobante, setCodigoComprobante] = useState(() => {
    const savedValue = localStorage.getItem('tilde');
    return savedValue ? Number(savedValue) : 1; // Por defecto, será '1' (pedido)
  });

  const handleCheckboxChange = (event) => {
    const newValue = Number(event.target.value);
    setCodigoComprobante(newValue);
    localStorage.setItem('tilde', newValue); // Guardamos el nuevo valor en localStorage
  };

  useEffect(() => {
    // Cada vez que 'codigoComprobante' cambie, guarda el valor en localStorage
    localStorage.setItem('tilde', codigoComprobante);
  }, [codigoComprobante]);

  return (
    <div className="CardComprobantes">
      <article
        className="cardTitleComprob"
        onClick={() => setCodigoComprobante(2)}
      >
        <header>
          <h2>Elegí el tipo de Comprobante</h2>
        </header>
      </article>
      <article
        className={`cardComprob textleft ${codigoComprobante === 2 ? 'active' : ''}`}
        onClick={() => setCodigoComprobante(2)}
      >
        <label>
          <input
            type="radio"
            name="comprobante"
            value="2"
            checked={codigoComprobante === 2}
            onChange={handleCheckboxChange}
          />
          <span>Presupuesto</span>
        </label>
      </article>
      <article
        className={`cardComprob textleft ${codigoComprobante === 1 ? 'active' : ''}`}
        onClick={() => setCodigoComprobante(1)}
      >
        <label>
          <input
            type="radio"
            name="comprobante"
            value="1"
            checked={codigoComprobante === 1}
            onChange={handleCheckboxChange}
          />
          Pedido
        </label>
      </article>
    </div>
  );
};
