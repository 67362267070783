import { useEffect, useState } from "react";
import Modal from "../../Modal/Modal";


const ModalListaCheckContent = ({
  data,
  itemSelecionados,
  setItemsSelecionados,
  setIsEdited,
  cerrarModal,
  title,
  setIdPrincipal,
  idPrincipal
}) => {
  const [datos, setDatos] = useState([]);

  useEffect(() => {
    const datalocal = data.map((i) => {
      return {
        id: i.id ? i.id : '',
        denominacion: i.descripcion
          ? i.descripcion
          : i.denominacion
            ? i.denominacion
            : '',
        defecto: i.defecto? i.defecto : 0
      };
    });
    setDatos(datalocal);
  }, [data]);

   

  const isChecked = (i) => {
    const encontrado = itemSelecionados.filter((j) => j.id === i.id);
    return encontrado.length > 0;
  }; 

  const handlePerfilChange = (i) => {
    setIsEdited(true);
    if (isChecked(i)) {
      
      setItemsSelecionados(itemSelecionados.filter((j) => j.id != i.id));
    } else {
      const seleccion = {
        id: i.id,
        denominacion: i.denominacion
      };

      setItemsSelecionados([...itemSelecionados, seleccion]);
    }
  };
  const handleChangePrincipal = (id)=>{
    setIdPrincipal(id)
    setIsEdited(true)
  }

  return (
    <Modal key={title} titulo={title} cerrarModal={cerrarModal}>
      <div className="modal-interior">
        {datos &&
          datos.map((i) => (
            <div>
              <label key={i.id}>
                <input
                  type="checkbox"
                  checked={isChecked(i)}
                  onChange={() => handlePerfilChange(i)}
                />
                {i.denominacion}
              </label>
              {isChecked(i) && 
              <label class="custom-checkbox">
                <input 
                    name='principal'
                    type="checkbox" 
                    checked={idPrincipal===i.id}
                    onChange={()=>handleChangePrincipal(i.id)}/>
                <span class="checkmark">Principal</span>
              </label>}
            </div>
          ))}
      </div>
    </Modal>
  );
};

export default ModalListaCheckContent;