import { useState, useEffect, useContext } from 'react';
import { API_PURCHASE_ID } from './ApiRoutes';
import { UserContext } from '../../../../auth/context/UserContext';

export const GetPurchaseId = (id) => {
  const [pedido, setPedido] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { dataUser } = useContext(UserContext);
  const { token } = dataUser;

  useEffect(() => {
    setLoading(true);
    fetch(`${API_PURCHASE_ID}${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        id: id,
        Authotization: token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Error al obtener pedido');
      })
      .then((data) => {
        // console.log('Pedidos obtenidos:', data);
        setPedido(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al obtener pedido:', error);
        setError(error);
        setLoading(false);
      });
  }, [id]);

  return { pedido, loading, error };
};
