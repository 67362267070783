import { useState, useEffect, useContext } from 'react';
import { API_PURCHASE_OK } from '../services/ApiRoutes';
import { UserContext } from '../../../auth/context/UserContext';

export const useGetPurchaseOk = (pagina) => {
  const [pedidos, setPedidos] = useState([]);
  const [paginas, setPaginas] = useState([]);
  const [total, setTotal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { dataUser } = useContext(UserContext);
  const { token, id } = dataUser;

  useEffect(() => {
    setLoading(true);
    const endpoint = `${API_PURCHASE_OK}?userId=${id}&pagina=${pagina}&porPagina=15`;

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        setPedidos(res.data);
        setPaginas(res.paginas);
        setTotal(res.total);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      });
  }, [token, id, pagina]);

  return { paginas, total, pedidos, loading, error };
};
