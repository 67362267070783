import React from 'react';
import { useUser } from '../../../auth/context/UserContext';
import './Carro.css';

import { Resume } from '../resume/Resume';
import { Header } from '../header/Header';
import { ItemsCart } from '../items/ItemsCart';
import { useState } from 'react';
import { useEffect } from 'react';
import { useShopingCart } from '../../hooks/useShopingCart';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Vouchers } from '../ResumeVouchers/Vouchers';

const Carro = () => {
  const { getTotal, getItems } = useShopingCart();
  const [itemTotal, setItemTotal] = useState(0);
  const items = getItems();

  useEffect(() => {
    const total = getTotal();
    setItemTotal(total);
  }, []);

  if (itemTotal === 0) {
    return (
      <div className="empty-screen">
        <p>ops.. el carrito esta vacio</p>
        <div>
          <Link to="/catalog/catalogo">
            <FontAwesomeIcon icon={faCartArrowDown} size="xl" />
            Agregar productos
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="containerCarrito">
      <h1 className="carrotitle">Cierre del Carrito</h1>
      <Header />
      {items && items?.length > 0 ? (
        <div className="contenedorCarrito">
          <div className="tarjetas">
            <ItemsCart items={items} />
          </div>
          <div className="comprobyresume">
            <Vouchers />
            <Resume carrito={{}} />
          </div>
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default Carro;
