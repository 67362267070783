import { useState, useEffect, useContext } from 'react';
import { API } from '../../../helpers/API';
import { UserContext } from '../../auth/context/UserContext';

export const useAcordeon = (id) => {
  const { dataUser } = useContext(UserContext);
  const perfiles = dataUser.perfiles;

  const [products, setProducts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProducts = async (id) => {
    try {
      const perfilesParam = perfiles.join(',');
      const res = await API.GET(
        `articulos/stockbyArticleId/${id}?perfiles=${perfilesParam}`
      );

      if (res.errors && res.errors.data) {
        const data = res.errors.data;

        setProducts({
          precios: data.precios,
          stock: data.Stock,
        });
      } else {
        setError('Formato de respuesta inesperado');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchProducts(id);
    }
  }, [id]);

  return { products, loading, error };
};
