import React from 'react';
import './css/Nico.css';
import './css/Styles.css';
import logo from '../public/logo192.png';

const Footer = () => (
  <footer className="footer">
    <small className="footersmall">
      © 2023 
      <a target="_blank" href="http://macrosistemassrl.com.ar" rel="noreferrer">
        MacroSistemas S.R.L
      </a>
      <img className="imagen_logo" src={logo} height="30px" />
      <pre>(Versión Beta) </pre>
    </small>
    <br />
  </footer>
);

export default Footer;
