import React, { useContext, useState, useEffect } from 'react';
import '../css/Nico.css';
import '../css/Styles.css';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { UserContext, useUser } from '../auth/context/UserContext';
import { back, front } from '../../helpers/dataServer';

const EditPerfil = () => {
  const { dataUser } = useContext(UserContext);
  const userData = dataUser;
  const { id } = useParams();

  const [nombre, setNombre] = useState(userData.nombre || '');
  const [apellido, setApellido] = useState(userData.apellido || '');
  const [email, setEmail] = useState(userData.email || '');
  const [telefono, setTelefono] = useState(userData.telefono || '');
  const [direccion, setDireccion] = useState(userData.direccion || '');
  const [localidad, setLocalidad] = useState(userData.localidad || '');
  const [fecha_nac, setFecha] = useState(userData.fecha_nac || '');
  const [imagen, setImagen] = useState(null);

  const [imagenUrl, setImagenUrl] = useState(userData.imagen || '');

  const { token } = dataUser;

  //const headers = new Headers();

  //headers.set('authorization', token);

  // console.log('\x1b[45m%s\x1b[0m', userData.id);

  // console.log('\x1b[45m%s\x1b[0m', token);

  // console.log('\x1b[45m%s\x1b[0m', token);

  useEffect(() => {
    // Realizar una solicitud GET al servidor para obtener la información de teléfono, localidad, dirección y fecha.
    fetch(`${back}/auth/getUserInfo/${userData.id}`, {
      headers: {
        Autorizathion: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Actualizar los estados con los datos obtenidos.
        setTelefono(data.telefono);
        setDireccion(data.direccion);
        setLocalidad(data.localidad);
        setFecha(data.fecha);
      })

      .catch((error) => {
        console.error('Error al obtener la información:', error);
      });
  }, []);

  const handleNombreChange = (event) => {
    setNombre(event.target.value);
  };

  const handleApellidoChange = (event) => {
    setApellido(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleTelefonoChange = (event) => {
    setTelefono(event.target.value);
  };

  const handleDireccionChange = (event) => {
    setDireccion(event.target.value);
  };

  const handleLocalidadChange = (event) => {
    setLocalidad(event.target.value);
  };

  const handleFechaChange = (event) => {
    setFecha(event.target.value);
  };

  /* const handleImagenChange = (event) => {
    setImagen(event.target.files[0]);
  }; */

  const handleImagenChange = (event) => {
    setImagen(event.target.files[0]);
    setImagenUrl(URL.createObjectURL(event.target.files[0])); // Actualiza la URL de la imagen al seleccionar un nuevo archivo
  };

  const handleCancelarClick = () => {
    setNombre(userData.nombre || '');
    setApellido(userData.apellido || '');
    setEmail(userData.email || '');
    setTelefono(userData.telefono || '');
    setDireccion(userData.direccion || '');
    setLocalidad(userData.localidad || '');
    setFecha(userData.fecha || '');
  };

  const handleGrabarClick = () => {
    const formData = new FormData();

    formData.append('id', userData.id);
    formData.append('nombre', nombre || userData.nombre || '');
    formData.append('apellido', apellido || userData.apellido || '');
    formData.append('email', email || userData.email || '');
    formData.append('telefono', telefono || userData.telefono || '');
    formData.append('direccion', direccion || userData.direccion || '');
    formData.append('localidad', localidad || userData.localidad || '');
    formData.append('fecha_nac', fecha_nac || userData.fecha_nac || '');

    if (imagen) {
      formData.append('imagen', imagen);
    }

    // Realizar la solicitud PATCH al servidor para actualizar la información

    // console.log(JSON.stringify(formData));
    fetch(`${back}/auth/update/${userData.id}`, {
      method: 'PATCH', // Utilizar el método PATCH o PUT según lo que uses en tu servidor
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        Swal.fire({
          title: 'Información actualizada con éxito',
          text: 'Actualización de infomación de Perfil Exitosa.',
          icon: 'success',
        });

        if (imagen) {
          setImagenUrl(URL.createObjectURL(imagen));
        }
      })
      .catch((error) => {
        console.error('Error al actualizar la información:', error);
        Swal.fire({
          title: 'Error al actualizar la información',
          text: 'Hubo un error al actualizar información de Perfil.',
          icon: 'error',
        });
      });
  };

  // let date = new Date()

  // let day = date.getDate()
  // let month = date.getMonth() + 1
  // let year = date.getFullYear()

  // if (month < 10) {
  //   console.log(`${day}-0${month}-${year}`)
  // } else {
  //   console.log(`${day}-${month}-${year}`)
  // }

  const [modalArtVisible, setModalArtVisible] = useState(false); // Estado para controlar la visibilidad del modal

  const abrirModalArt = () => {
    setModalArtVisible(true);
  };

  const cerrarModalArt = () => {
    setModalArtVisible(false);
  };

  return (
    <main className="main">
      <div className="row">
        <div className="edit_perfil">
          <div className="volver">
            <Link to="/catalog/catalogo">
              <i className="fa-solid fa-caret-left">Volver</i>
            </Link>
          </div>

          <h1>Tu información de perfil en los servicios de MacroSistemas</h1>
          <p>
            Encontrarás tu información de perfil y las opciones para
            administrarla.
          </p>
          <div className="ibox">
            <div className="ibox-title">
              <h3>Información Básica</h3>
            </div>
            <div className="ibox-content">
              <div className="carta_perfil_contenedor">
                <div className="div shoping-cart-div">
                  {/* <div className='info_carta_perfil_foto'>
                    <div className='label-edit'>
                      Foto de Perfil:
                    </div>
                    <div>
                      <label htmlFor="file-input" > <i className="fa-solid fa-file-image fa-lg" style={{ color: "#393e47" }}><h5>Subir archivo</h5></i></label>
                      <input id="file-input" type="file" onChange={handleImagenChange} style={{ display: 'none' }} />
                    </div>
                  </div> */}

                  <div className="info_carta_perfil_foto">
                    <div className="label-edit">Foto de Perfil:</div>
                    <div className="foto_input">
                      <div>
                        <label htmlFor="file-input">
                          <i
                            className="fa-solid fa-file-image fa-lg"
                            style={{ color: '#393e47' }}
                          >
                            <h5>Subir archivo</h5>
                          </i>
                        </label>
                      </div>

                      <div className="foto_archi">
                        <input
                          id="file-input"
                          type="file"
                          onChange={handleImagenChange}
                          style={{ display: 'none' }}
                        />
                        <img
                          className="imagen_perfil_edit"
                          src={imagenUrl}
                          alt="Imagen de perfil"
                          style={{ height: '50px', width: '50px' }}
                        />{' '}
                        {/* Muestra la imagen actual */}
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="info_carta_perfil">
                    <div className="label-edit">Nombre:</div>
                    <div>
                      <input
                        type="text"
                        id="nombre"
                        name="nombre"
                        value={nombre || userData.nombre}
                        onChange={handleNombreChange}
                        style={{ display: 'flex', flex: '1' }}
                      />
                    </div>
                  </div>

                  <hr />
                  <div className="info_carta_perfil">
                    <div className="label-edit">Apellido:</div>
                    <div>
                      <input
                        type="text"
                        id="apellido"
                        name="apellido"
                        value={apellido || userData.apellido}
                        onChange={handleApellidoChange}
                        style={{ display: 'flex', flex: '1' }}
                      />
                    </div>
                  </div>

                  <hr />
                  <div className="info_carta_perfil">
                    <div className="label-edit">Fecha de Nacimiento:</div>
                    <div>
                      <input
                        type="text"
                        id="fecha_nac"
                        name="fecha_nac"
                        value={fecha_nac}
                        onChange={handleFechaChange}
                        style={{ display: 'flex', flex: '1' }}
                      />
                    </div>

                    {/* <i className="fa-solid fa-chevron-right" onClick={abrirModalArt}></i> */}
                  </div>

                  <hr />
                </div>
              </div>
            </div>
            <div className="ibox-content-final">
              <button className="btn btn-white" onClick={handleCancelarClick}>
                Cancelar
              </button>
              <button
                className="btn btn-primary float-right"
                onClick={handleGrabarClick}
              >
                Grabar
              </button>
            </div>
          </div>
          <br />
          <div className="ibox">
            <div className="ibox-title">
              <h3>Información de Contacto</h3>
            </div>
            <div className="ibox-content">
              <div className="carta_perfil_contenedor">
                <div className="div shoping-cart-div">
                  <div className="info_carta_perfil">
                    <div className="label-edit">Correo electrónico:</div>
                    <div>
                      <p style={{ marginRight: '10px' }}>{email}</p>
                    </div>
                  </div>

                  <hr />
                  <div className="info_carta_perfil">
                    <div className="label-edit">Teléfono:</div>
                    <div>
                      <input
                        type="text"
                        id="telefono"
                        name="telefono"
                        value={telefono || userData.telefono}
                        onChange={handleTelefonoChange}
                        style={{ display: 'flex', flex: '1' }}
                      />
                    </div>
                  </div>

                  <hr />
                  <div className="info_carta_perfil">
                    <div className="label-edit">Dirección:</div>
                    <div>
                      <input
                        type="text"
                        id="direccion"
                        name="direccion"
                        value={direccion || userData.direccion}
                        onChange={handleDireccionChange}
                        style={{ display: 'flex', flex: '1' }}
                      />
                    </div>
                  </div>

                  <hr />
                  <div className="info_carta_perfil">
                    <div className="label-edit">Localidad:</div>
                    <div>
                      <input
                        type="text"
                        id="localidad"
                        name="localidad"
                        value={localidad || userData.localidad}
                        onChange={handleLocalidadChange}
                        style={{ display: 'flex', flex: '1' }}
                      />
                    </div>
                  </div>

                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div className="ibox-content-final">
            <button className="btn btn-white" onClick={handleCancelarClick}>
              Cancelar
            </button>
            <button
              className="btn btn-primary float-right"
              onClick={handleGrabarClick}
            >
              Grabar
            </button>
          </div>
        </div>
      </div>

      {/* Modal de Edicion de perfil */}
      <div
        className="modal-edit-perfil"
        id="modal-edit-perfil"
        style={{ display: modalArtVisible ? 'block' : 'none' }}
      >
        <div>
          {/* Modal de Artículo */}
          <div
            className="modal-art"
            id="modal-art"
            style={{ display: modalArtVisible ? 'block' : 'none' }}
          >
            <div className="modal-content-perfil">
              <div>
                {/* Modal de Artículo */}
                <div
                  className="modal-art"
                  id="modal-art"
                  style={{ display: modalArtVisible ? 'block' : 'none' }}
                >
                  <div className="modal-content-perfil">
                    {/*  <div className='modal-contenido-perfil'> */}
                    <span
                      className="close"
                      onClick={cerrarModalArt}
                      style={{ left: 0 }}
                    >
                      &times;
                    </span>
                    <h2>{}</h2>
                    <hr />
                    <p>{}</p>
                    <span>Edición de {}</span>
                    <h2 className="font-bold">{}</h2>
                    <div className="m-t-sm">
                      <div className="btn-group">
                        <button className="btn btn-primary btn-sm">
                          Aceptar
                        </button>
                      </div>
                      <div className="btn-group">
                        <a
                          href="#"
                          onClick={cerrarModalArt}
                          className="btn btn-white btn-sm"
                        >
                          Cancelar
                        </a>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <i className="fa-solid fa-arrow-right" onClick={abrirModalArt} />
        </div>
      </div>
    </main>
  );
};

export default EditPerfil;
