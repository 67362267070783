import { useEffect, useState } from 'react';
import { useShopingCart } from '../../../ShoppingCart/hooks/useShopingCart';

import removeItems from '../../../ShoppingCart/assets/removeItems.svg';
import plus from '../../../ShoppingCart/assets/addItems.svg';

import './ButtonsActionsCart.css';

export const ButtonsActionsCart = ({ articulo, precios, moneda }) => {
  const { setItem, dataClient, getItems } = useShopingCart();
  const [cantidad, setCantidad] = useState(0);
  useEffect(() => {
    // Si este producto existe en el carrito, obtine la cantidad y la muestra en el contador de los botones.
    const count =
      dataClient?.denominacion !== ''
        ? getItems().find((p) => p.id === articulo.id)?.cantidad ?? 0
        : 0;
    setCantidad(count);
  }, [articulo?.id, dataClient?.denominacion, getItems]); // Añadido useEffect para controlar el cambio en dataClient?.denominacion

  const stockUnitario = articulo?.Stock || 0;
  const eliminarItemDelCarrito = () => {
    if (cantidad >= 1) {
      const nuevaCantidad = cantidad - 1;
      setCantidad(nuevaCantidad);
      setItem({ ...articulo, cantidad: nuevaCantidad, precios });
    }
  };

  const agregarAlCarrito = () => {
    const precioctrl = precios === undefined ? 0 : precios.monto_neto; //controlamos el precio si viene undefined
    if (precioctrl > 0) {
      const nuevaCantidad = cantidad + 1;
      setCantidad(nuevaCantidad);
      setItem({ ...articulo, cantidad: nuevaCantidad, precios });
    }
  };

  return (
    <div className="fg-8 tabla__contenido buttons-actions-cart">
      <button
        type="button"
        id={articulo.id}
        value={articulo.id}
        className="selectorItemsCatalog"
        onClick={() => eliminarItemDelCarrito(articulo)}
      >
        <img src={removeItems} alt="Quita Items" />
      </button>
      <span className={cantidad !== 0 ? 'counter' : null}>{cantidad}</span>
      <button
        type="button"
        id={articulo.id}
        value={articulo.id}
        className="selectorItemsCatalog"
        onClick={() => agregarAlCarrito(articulo)}
      >
        <img src={plus} alt="Agrega Items" />
      </button>
    </div>
  );
};
