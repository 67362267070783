import React, { useState, useEffect, useContext } from 'react';

import '../../css/Styles.css';
import '../../css/Tablas.css';
import '../css/PerfilesUsuarios.css';
import { back } from '../../../helpers/dataServer';
import { ItemGrillaPerfiles } from '../components/ItemGrillaPerfiles';
import { UserContext } from '../../auth/context/UserContext';

export const Perfiles = () => {
  const [perfiles, setPerfiles] = useState([]);
  const [Depositos, setDepositos] = useState([]);
  const [Precios, setPrecios] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [rengNuevo, setRengNuevo] = useState(false);
  const [perfilBuscado, setPefilesBuscado] = useState(perfiles);
  const { dataUser } = useContext(UserContext);

  const { token } = dataUser;


  useEffect(() => {
    fetch(`${back}/perfiles/listperfiles`, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPerfiles(data.data);
      })
      .catch((error) => {
        console.error('Error al obtener la lista de perfiles:', error);
      });
  }, [token, refresh]);

  useEffect(() => {
    fetch(`${back}/depositos/listdeposito`, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const arrDepo = [];
        if (data && data.data) {
          data.data.map((i) => {
            arrDepo.push({
              id: i.id,
              denominacion: i.descripcion,
            });
          });
        }
        setDepositos(arrDepo);
      })
      .catch((error) => {
        console.error('Error al obtener la lista de depositos:', error);
      });
  }, []);

  useEffect(() => {
    fetch(`${back}/params/listtipolistaprecios`, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const arrPrecio = [];
        if (data && data.errors && data.errors.data) {
          data.errors.data.map((i) => {
            arrPrecio.push({
              id: i.id,
              denominacion: i.denominacion,
            });
          });
        }
        setPrecios(arrPrecio);
      })
      .catch((error) => {
        console.error('Error al obtener los precios:', error);
      });
  }, []);

  const search = (e) => {
    const filtro = perfiles.filter((i) =>
      i.denominacion.includes(e.target.value)
    );

    if (filtro.length === 0) {
    }

    setPefilesBuscado(filtro);
  };

  return (
    <div className="grandContainerPerfiles">
      <h1 className="titleProfilesUser">Perfiles Usuarios</h1>
      {!rengNuevo && (
        <div className="searchProfileContainer">
          <button
            onClick={() => setRengNuevo(!rengNuevo)}
            className="btnAddProfile"
          >
            Nuevo Perfil
          </button>

          <div>
            <input
              type="text"
              onChange={(e) => search(e)}
              id="search-input"
              className="searchProfile"
              placeholder="Buscar..."
            />
          </div>
        </div>
      )}
      <article className="contenedorCardProfiles">
        {rengNuevo && (
          <ItemGrillaPerfiles
            setRengNuevo={setRengNuevo}
            rengNuevo={true}
            refresh={refresh}
            setRefresh={setRefresh}
            item={{}}
            depositos={Depositos}
            precios={Precios}
          />
        )}
        {perfiles &&
          perfiles.map((perfil) => (
            <ItemGrillaPerfiles
              key={perfil.id}
              setRengNuevo={setRengNuevo}
              rengNuevo={false}
              refresh={refresh}
              setRefresh={setRefresh}
              item={perfil}
              depositos={Depositos}
              precios={Precios}
            />
          ))}
      </article>
    </div>
  );
};
