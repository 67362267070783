import { useContext, useState, useCallback } from 'react';
import { back } from '../../../helpers/dataServer';
import { UserContext } from '../../auth/context/UserContext';

export const useCliente = () => {
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [clienteId, setClienteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { dataUser } = useContext(UserContext);
  const { token } = dataUser;

  const fetchClientes = useCallback(
    async (filter) => {
      setLoading(true);
      setError(null);
      try {
        let endpoint = `${back}/clientes`;
        const dataFetch = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', authorization: token },
        };

        if (filter) {
          endpoint += `?denominacion=${filter}`;
        }

        const response = await fetch(endpoint, dataFetch);
        const result = await response.json();

        if (response.ok) {
          setFilteredClientes(result.errors.data);
          return result.errors.data;
        } else {
          setError(result.errors.msg);
          console.error(result.errors.msg);
        }
      } catch (error) {
        setError('Error al obtener los clientes');
        console.error('Error al obtener los clientes:', error);
      } finally {
        setLoading(false);
      }
      return filteredClientes;
    },
    [token]
  );

  const getClientById = useCallback(
    async (id) => {
      setLoading(true);
      setError(null);
      try {
        const endpoint = `${back}/clientes/${id}`;
        const dataFetch = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', authorization: token },
        };

        const response = await fetch(endpoint, dataFetch);
        const result = await response.json();

        if (response.ok) {
          setClienteId(result.errors.data);
          return result.errors.data;
        } else {
          setError(result.errors.msg);
          console.error(result.errors.msg);
        }
      } catch (error) {
        setError('Error al obtener el cliente por ID');
        console.error('Error al obtener el cliente por ID:', error);
      } finally {
        setLoading(false);
      }
      return clienteId;
    },
    [token]
  );

  return {
    fetchClientes,
    getClientById,
    filteredClientes,
    clienteId,
    loading,
    error,
  };
};
