import React from 'react';
import './PrintGridOrder.css';

export const PrintGridOrder = ({
  items,
  getCurrencySymbol,
  formatPrecio,
  moneda,
}) => {
  return (
    <div className="contenedorGridOrder">
      {items.map((item) => (
        <article>
          <header>
            <div className="itemDescripcion">{item.Articulos}</div>
          </header>
          <ul>
            <li>
              <span>Precio:</span>
              <span>
                {getCurrencySymbol(moneda)} {item.precio}
              </span>
            </li>
            <li>
              <span>Cantidad:</span>
              <span>{item.cantidad}</span>
            </li>
            <li>
              <span>Descuento</span>
              <span>{item.porc_desc}</span>
            </li>
            <li>
              <span>SubTotal:</span>
              <span className="textBold">
                {getCurrencySymbol(moneda)}{' '}
                {formatPrecio(item.precio, item.cantidad)}
              </span>
            </li>
          </ul>
        </article>
      ))}
    </div>
  );
};
