import '../cart/Carro.css';
import { RowsItems } from './RowsItems';
// import { GetArt } from "./services/GetArt";

export const ItemsCart = ({ items }) => {
  const itemCart = items.length > 0 ? items : [];

  return (
    <div className="contenedorTarjetas">
      {itemCart.map((item, index) => (
        <RowsItems item={item} index={index} />
      ))}
    </div>
  );
};
