import { SendBuy } from '../../services/SendBuy';
import './Resume.css';
import '../cart/Carro.css';
import { useShopingCart } from '../../hooks/useShopingCart';
import ComprasAnteriores from '../purchases/comprasAnteriores';
import { useContext } from 'react';
import { UserContext } from '../../../auth/context/UserContext';
import { useNavigate } from 'react-router-dom';
import { useClient } from '../../../catalogo/hooks/useClient';
import { ResumeCard } from '../resumeCard/ResumeCard';

export const Resume = () => {
  const { clearItems } = useShopingCart();
  const { dataUser } = useContext(UserContext);

  const { clearClient } = useClient();

  const navigate = useNavigate();
  // const { id: clientId } = dataClient || {};

  const eliminarItemDelCarrito = () => {
    clearItems();
    clearClient();
    navigate('/catalog/catalogo');
  };

  // const items = getItems().filter((item) => item.cantidad > 0);
  // //ARMADO DE PEDIDO
  // const productos = items.map(
  //   ({ id, cantidad, precios, moneda, porc_descuento }) => {
  //     precios.descuento = porc_descuento;

  //     return {
  //       id: id,
  //       cantidad: cantidad,
  //       precio: precios,
  //       moneda: moneda,
  //       descuento: porc_descuento,
  //     };
  //   }
  // );

  // console.log(items);
  // const codComp = localStorage.getItem('tilde');
  // const pedido = [
  //   {
  //     clientId,
  //     user: dataUser.id,
  //     products: productos,
  //     codigo_comprobante: codComp,
  //   },
  // ];

  return (
    <div className="cardResume">
      <article className="contenedorCardTotal sticky-footer">
        <header>
          <h2>Resumen</h2>
        </header>
        <ResumeCard />
        <hr className="lineaTotal" />
        <section className="contenedorBtnTot">
          <button
            className="transparent"
            type="button"
            onClick={eliminarItemDelCarrito}
          >
            Cancelar
          </button>
          <SendBuy /*pedido={pedido}*/ user={dataUser.id} />
        </section>
      </article>
      <ComprasAnteriores titulo="Compras Anteriores" />
    </div>
  );
};
