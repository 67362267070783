import { format } from '../../../../../helpers/formatter';
import { useInfoSync } from '../../../../../Hooks/useInfoSync';
import { Articulo } from '../../../../Productos/Articulo';
import { ButtonsActionsCart } from '../../ButtonsActionsCart/ButtonsActionsCart';

export const ProductRow = ({
  articulo,
  stockUnitario,
  showActions,
  precios,
  priceToDisplay,
}) => {
  const { sinonimo, codigo_mg } = articulo;

  const { data: dataInfoSync } = useInfoSync();
  const monedaData = dataInfoSync?.moneda_carrito || 0;

  return (
    <tr className="renglon-posicion">
      <td className="tabla__contenido codigo">{codigo_mg}</td>
      <td className="tabla__contenido codigo codigo_sin" title={sinonimo}>
        {sinonimo}
      </td>
      <td className="tabla__contenido tabla__denom descripcion">
        <Articulo articulo={articulo} />
      </td>
      <td className="tabla__contenido tabla__num stock">{stockUnitario}</td>
      <td className="tabla__contenido tabla__num precio">
        {format.currency(monedaData, priceToDisplay) || 0}
      </td>
      {showActions && (
        <td className="cell-actions-table">
          <ButtonsActionsCart
            articulo={articulo}
            precios={precios}
            moneda={monedaData}
          />
        </td>
      )}
    </tr>
  );
};
