import React, { useState, useEffect } from 'react';
import Modal from '../../../Modal/Modal.jsx';
import { useGetPurchaseOk } from '../hooks/useGetPurchaseOk.js';
import { PrintOrder } from '../../../ShoppingCart/components/printPurchase/PrintOrder.jsx';
import './Confirm.css';
import '../../../css/Tablas.css';
import useModal from '../../../../Hooks/useModal.js';
import {
  formatDate,
  formatSucursal,
  formatNumero,
} from '../../../ShoppingCart/hooks/useFormat.js';
import { format } from '../../../../helpers/formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const safeValue = (value) => (isNaN(value) ? 0 : value);

export const Confirm = () => {
  const [pagina, setPagina] = useState(1);
  const { pedidos, loading, paginas } = useGetPurchaseOk(pagina);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPedido, setSelectedPedido] = useState(null);

  const openModal = (pedido) => {
    setSelectedPedido(pedido);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedPedido(null);
    setModalOpen(false);
  };

  const { active, toggle } = useModal();
  const cerrarModal = () => {
    const activarDesactivar = !active;
    toggle(activarDesactivar);
    closeModal();
  };

  const sumaPagina = () => {
    if (pagina < paginas) {
      setPagina(pagina + 1);
    }
  };

  const restaPagina = () => {
    if (pagina > 1) {
      setPagina(pagina - 1);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedPedido(null);
      setModalOpen(false);
    };
  }, []);

  return (
    <>
      <div className="pageConfirmContainer">
        <h1 className="historialTitu">Historial de Comprobantes</h1>
        {loading && <p>Cargando...</p>}

        {!loading &&
          pedidos.map((pedido) => {
            const finalPrice = pedido.total;
            const formattedSucursal = formatSucursal(pedido.sucursal_mg || '0');
            const formattedNumero = formatNumero(pedido.numero_mg || '0');
            const moneda = pedido.Comprobantes_items[0]?.moneda || 0;
            const tipoComp = pedido.id_tipo_comprobante;

            let descReducida = 'PRV';
            if (tipoComp === 1) {
              descReducida = 'PEV';
            }

            return (
              <article className="contenedorCardConfirm" key={pedido.id}>
                <section className="cardConfirm">
                  <p className="fechaPed">{formatDate(pedido.fecha)}</p>
                  <div className="contenedorSucNum">
                    <span
                      className={`modal-estado ${descReducida === 'PRV' ? 'clase-prv' : 'clase-pev'}`}
                    >
                      {descReducida}
                    </span>
                    <span> {formattedSucursal} - </span>
                    <span>{formattedNumero}</span>
                  </div>
                  <div className="contenedorDenom">
                    <button onClick={() => openModal(pedido)}>
                      <span className="denominacion">
                        {pedido.Cliente
                          ? pedido.Cliente.codigo_mg
                          : 'Cliente no encontrado'}{' '}
                        -{' '}
                        {pedido.Cliente
                          ? pedido.Cliente.denominacion
                          : 'Denominacion no encontrada'}
                      </span>
                    </button>
                  </div>
                  <h2 className="totalItems">
                    {format.currency(moneda, safeValue(finalPrice))}
                  </h2>
                </section>
              </article>
            );
          })}

        <div className="paginacion">
          <button
            className="paginacion-antes"
            onClick={restaPagina}
            disabled={pagina === 1}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              size="xl"
              className="iconleft"
            />
            Anterior
          </button>
          <span className="paginacion-centro">
            {pagina} de {paginas}
          </span>
          <button
            className="paginacion-despues"
            onClick={sumaPagina}
            disabled={pagina === paginas}
          >
            Siguiente
            <FontAwesomeIcon
              icon={faChevronRight}
              size="xl"
              className="iconright"
            />
          </button>
        </div>
      </div>

      {modalOpen && selectedPedido && (
        <Modal titulo={'Detalle'} cerrarModal={cerrarModal}>
          <PrintOrder pedido={selectedPedido} cerrarModal={cerrarModal} />
        </Modal>
      )}
    </>
  );
};
