import React, { useState, useEffect } from 'react';
import '../../css/ModalPerfiles.css';
import '../css/PerfilesUsuarios.css';

import ModalListaCheckContent from './ModaListaCheckContent';
 
const ModalListaCheck = ( {data,
  itemSelecionados,
  setItemsSelecionados,
  setIsEdited,
  title,
  setIdPrincipal,
  idPrincipal}) => {

  const [modalVisible, setModalVisible] = useState(false);
  const cerrarModal = () => {
    setModalVisible(false);
  };

  const abrirModal = () => {
    setModalVisible(true);
  };

  return (
    <>
      <button onClick={abrirModal}>Editar</button>
      {modalVisible && (
        <ModalListaCheckContent 
         data ={data}
          itemSelecionados={itemSelecionados}
          setItemsSelecionados={setItemsSelecionados}
          setIsEdited={setIsEdited}
          title={title}
          setIdPrincipal={setIdPrincipal}
          idPrincipal={idPrincipal}
          cerrarModal={cerrarModal} />
      )}
    </>
  );
};


export default ModalListaCheck;
