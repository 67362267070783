import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import removeItems from '../../assets/removeItems.svg';
import plus from '../../assets/addItems.svg';
import {
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { formatPrecio } from '../../hooks/useFormat';
import { useShopingCart } from '../../hooks/useShopingCart';

import '../cart/Carro.css';
import 'animate.css';
import { format } from '../../../../helpers/formatter';

export const RowsItems = ({ item, index }) => {
  const {
    handleDiscountProduct,
    handleIncreaseProduct,
    handleChangeProduct,
    handleDeleteProduct,
    currencyCart,
    handleDiscountChange,
    // getItemsExeds,
    // setItem,
  } = useShopingCart();

  const [showDialog, setShowDialog] = useState(false);
  const [montoNetoCDcto, setMontoNetoCDcto] = useState(null);
  const [precioMinimo, setPrecioMinimo] = useState(null);

  const handleShowDialog = (montoNetoCDcto, precioMinimo) => {
    setMontoNetoCDcto(montoNetoCDcto);
    setPrecioMinimo(precioMinimo);
    setShowDialog(true);
  };

  const handleInputChange = async (e) => {
    let newValue = e.target.value.replace(/[^0-9]/g, '');

    if (parseInt(newValue) > 99) {
      newValue = '99';
    }
    //setItem({ ...item, descuento: newValue });

    const { precioExcedido, montoNetoCDcto } = handleDiscountChange(item)({
      target: { value: newValue },
    });

    if (precioExcedido) {
      handleShowDialog(montoNetoCDcto, item.precios.precio_minimo);
    } else {
      setShowDialog(false);
    }
  };

  // useEffect(() => {
  //   const isItemExceded = getItemsExeds(item.id);

  //   if (isItemExceded) {
  //     handleShowDialog(
  //       item.precios.monto_neto_con_descuento,
  //       item.precios.precio_minimo
  //     );
  //   } else {
  //     setShowDialog(false);
  //   }
  // }, [
  //   //getItemsExeds,
  //   item.id,
  //   item.precios.monto_neto_con_descuento,
  //   item.precios.precio_minimo,
  // ]);

  // useEffect(() => {
  //   handleInputChange({
  //     target: { value: String(parseInt(discountValue)) },
  //   });
  // }, [discountValue]);

  return (
    <article className="contenedorCardCarrito" key={item.codigo_mg}>
      <section className="cardCarrito">
        <h2 className="tituloCard contenedorTituloBtn">{item.denominacion}</h2>
        <button
          type="button"
          className="btnEliminarCard"
          onClick={handleDeleteProduct(item)}
        >
          <FontAwesomeIcon icon={faTrash} />
          Eliminar
        </button>

        <div className="contenedorDescuento">
          <span className="carritoDescuento">Descuento</span>
          <input
            className={
              showDialog
                ? 'selectordescexed animate__animated animate__headShake'
                : 'selectordesc'
            }
            value={item.porc_descuento}
            onChange={handleInputChange}
            placeholder="0"
            max="99" // Limitar el valor máximo
            onKeyDown={(e) => {
              const allowedKeys = [
                'Backspace',
                'Tab',
                'ArrowUp',
                'ArrowDown',
                'ArrowLeft',
                'ArrowRight',
              ];
              if (!e.key.match(/[0-9]/) && !allowedKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }}
          />
          <span>%</span>
        </div>

        <div className="contSelectorTot">
          <section className="selectorCant">
            <button
              type="button"
              className="selectorItems"
              onClick={handleDiscountProduct(item)}
            >
              <img src={removeItems} alt="Quita Items" />
            </button>
            <input
              className="selectorItems"
              onChange={handleChangeProduct(item)}
              value={item.cantidad}
            />
            <button
              type="button"
              className="selectorItems"
              onClick={handleIncreaseProduct(item)}
            >
              <img src={plus} alt="Agrega Items" />
            </button>
          </section>
        </div>

        <div className="selectorTotal">
          {item.porc_descuento > 0 && (
            <div className="handleTotDesc">
              <span className="CantDesc">-{item.porc_descuento}%</span>
              <span className="totSinDesc">
                {format.currency(
                  currencyCart,
                  formatPrecio(
                    item?.precios?.monto_con_iva_con_descuento,
                    item.cantidad || 0
                  )
                )}
              </span>
            </div>
          )}
          <h2 className="totalItems">
            {format.currency(
              currencyCart,
              formatPrecio(item?.precios?.monto_con_iva, item.cantidad || 0)
            )}
          </h2>
        </div>
      </section>

      {showDialog && (
        <div className="animate__animated animate__zoomIn alert">
          <FontAwesomeIcon
            className="warningIcon"
            icon={faTriangleExclamation}
          />
          <span className="alertText">
            Se excedió el precio mínimo. Monto:{' '}
            {format.currency(currencyCart, montoNetoCDcto)}, Precio mínimo:{' '}
            {format.currency(currencyCart, precioMinimo)}
          </span>
        </div>
      )}
    </article>
  );
};
