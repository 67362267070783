import { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faSpinner,
  faFloppyDisk,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { back } from '../../../helpers/dataServer';
import { UserContext } from '../../auth/context/UserContext';
import '../css/PerfilesUsuarios.css';
import ModalListaCheck from './ModalListaCheck';

export const ItemGrillaPerfiles = ({
  item,
  depositos,
  precios,
  refresh,
  setRefresh,
  setRengNuevo,
  rengNuevo = false,
}) => {
  const [depositosSelecionados, setDepositosSeleccionados] = useState([]);
  const [todosLosDepositos, SetTodosLosDepositos] = useState([]);
  const [preciosSelecionados, setPreciosSeleccionados] = useState([]);
  const [todosLosPrecios, SetTodosLosPrecios] = useState([]);
  const [denoNuevo, setDenoNuevo] = useState();
  const [isEdited, setIsEdited] = useState(false);
  const { dataUser } = useContext(UserContext);
  const [isFetching, setIsFetching] = useState(false);
  const [idPrecioPrincipal, setIdPrecioPrincipal] = useState()
  const [idDepositoPrincipal, setIdDepositoPrincipal] = useState()
  const { token } = dataUser;

  useEffect(() => {
    setIsFetching(true);
    fetch(`${back}/perfilesdepositos/listbyid/${item.id}`)
      .then((response) => response.json())
      .then((data) => {
        const arrDepo = [];
        if (data && data.errors && data.errors.data) {

          data.errors.data.map((i) => {
            arrDepo.push({
              id: i.id_deposito,
              denominacion: i.denominacion,
              defecto:i.defecto
            });
            if (i.defecto===1){setIdDepositoPrincipal(i.id_deposito)}
          });
        }
        setDepositosSeleccionados(arrDepo);
      })
      .catch((error) => {
        console.error('Error al obtener la lista de depositos:', error);
      })
      .finally(() => setIsFetching(false));
  }, []);

  useEffect(() => {
    setIsFetching(true);
    fetch(`${back}/perfilesprecios/listbyid/${item.id}`)
      .then((response) => response.json())
      .then((data) => {
        const arrPrecio = [];
        if (data && data.errors && data.errors.data) {
          data.errors.data.map((i) => {
            arrPrecio.push({
              id: i.id_tipo_precio,
              denominacion: i.denominacion,
              defecto: i.defecto 
            });
            if (i.defecto===1){setIdPrecioPrincipal(i.id_tipo_precio)}
          });
        }
        setPreciosSeleccionados(arrPrecio);
      })
      .catch((error) => {
        console.error('Error al obtener la lista de precios:', error);
      })
      .finally(() => setIsFetching(false));
  }, []);

  const Grabar = () => {

    const depositosSel = depositosSelecionados.map((i) => {
      return {  id:i.id , 
                defecto: i.id === idDepositoPrincipal?1:0 
              }
            });
    const preciosSel = preciosSelecionados.map((i) => {
      return {  id:i.id , 
                defecto: i.id === idPrecioPrincipal?1:0 
              }
            });

    if (depositosSel.length == 0) {
      Swal.fire({
        title: 'No se pudo crear el Perfil',
        text: 'Debe al menos seleccionar un deposito.',
        icon: 'error',
      });
      return 1;
    }
    if (preciosSel.length == 0) {
      Swal.fire({
        title: 'No se pudo crear el Perfil',
        text: 'Debe al menos seleccionar un precio.',
        icon: 'error',
      });
      return 1;
    }

    let perfil = {};
    let apiurl = `${back}/perfiles/addperfiles`;
    let esNuevo = false;
    if (item.id > 0) {
      apiurl = `${back}/perfiles/updperfiles`;
      perfil = {
        id: item.id,
        denominacion: denoNuevo,
        depositos: depositosSel,
        listas: preciosSel,
      };
      // isEdited(true)
    } else {
      esNuevo = true;
      perfil = {
        denominacion: denoNuevo,
        depositos: depositosSel,
        listas: preciosSel,
      };
    }

    setIsFetching(true);
    fetch(apiurl, {
      method: 'POST',
      headers: {
        authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(perfil),
    })
      .then((response) => response.json())
      .then((data) => {
        Swal.fire({
          title: 'Perfil',
          text: 'Se agregó el perfil correctamente.',
          icon: 'success',
        });
        if (data.data.id) {
          setRefresh(!refresh);
          if (esNuevo) {
            setRengNuevo(false);
          }
          setIsEdited(false);
        }
      })
      .catch((error) => {
        console.error('Error al grabar el perfil:', error);
      })
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    SetTodosLosDepositos(depositos);
  }, [depositos]);

  useEffect(() => {
    SetTodosLosPrecios(precios);
  }, [precios]);

  const Eliminar = () => {
    setIsFetching(true);
    fetch(`${back}/perfiles/delperfiles`, {
      method: 'DELETE',
      headers: {
        authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: item.id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.status == 200) {
          setRefresh(!refresh);
          Swal.fire({
            title: 'Perfil',
            text: 'Se eliminó el perfil correctamente.',
            icon: 'success',
          });
        }
      })
      .catch((error) => {
        console.error('Error al borrar el perfil:', error);
      })
      .finally(() => setIsFetching(false));
  };

  return (
    <section className="cardProfiles">
      <span className="profileDenom">
        {!rengNuevo ? (
          item.denominacion
        ) : (
          <input
            type="text"
            className="input"
            placeholder="Nombre"
            onChange={(e) => {
              setDenoNuevo(e.target.value);
            }}
          />
        )}
      </span>
      <div className="profileDepo">
        <span>Depósitos: </span>
        <div>
          {depositosSelecionados.map((i) => (
            <span className="pill" key={i.id}>
              {i.denominacion}{i.id === idDepositoPrincipal?'*':''}
            </span>
          ))}
        </div>
        <ModalListaCheck
          data={todosLosDepositos}
          itemSelecionados={depositosSelecionados}
          setItemsSelecionados={setDepositosSeleccionados}
          setIsEdited={setIsEdited}
          title="Depósitos"
          setIdPrincipal={setIdDepositoPrincipal}
          idPrincipal={idDepositoPrincipal}
        />
      </div>
      <div className="profileDepo profilePrec">
        <span>Precios: </span>
        <div>
          {preciosSelecionados.map((i) => (
            <span className="pill" key={i.id}>
              {i.denominacion}{i.id === idPrecioPrincipal?'*':''}
            </span>
          ))}
        </div>
        <ModalListaCheck
          data={todosLosPrecios}
          itemSelecionados={preciosSelecionados}
          setItemsSelecionados={setPreciosSeleccionados}
          setIsEdited={setIsEdited}
          title="Precios"
          setIdPrincipal={setIdPrecioPrincipal}
          idPrincipal={idPrecioPrincipal}
        />
      </div>
      <div className="profileClear">
        {item.denominacion && isEdited ? (
          <button className="btnSave" onClick={Grabar}>
            {isFetching ? (
              <FontAwesomeIcon icon={faSpinner} size="xl" spin />
            ) : (
              <FontAwesomeIcon icon={faFloppyDisk} size="xl" />
            )}
          </button>
        ) : item.denominacion && !isEdited ? (
          <button
            className="btnTrashSave"
            onClick={Eliminar}
            disabled={isFetching}
          >
            {isFetching ? (
              <FontAwesomeIcon icon={faSpinner} size="xl" spin />
            ) : (
              <FontAwesomeIcon icon={faTrash} size="xl" />
            )}
          </button>
        ) : (
          <>
            <button className="btnSave" onClick={Grabar} disabled={isFetching}>
              {isFetching ? (
                <FontAwesomeIcon icon={faSpinner} size="xl" spin />
              ) : (
                <FontAwesomeIcon icon={faFloppyDisk} size="xl" />
              )}
            </button>
            <button
              className="btnSave"
              onClick={() => setRengNuevo(false)}
              disabled={isFetching}
            >
              <FontAwesomeIcon icon={faXmark} size="xl" />
            </button>
          </>
        )}
      </div>
    </section>
  );
};
