import Cookies from 'universal-cookie';
import { back as host } from './dataServer';

const dispatchFetch = (method) => async (path, options) => {
  // Get all attributes nedded.

  const { body, headers, ...restOptions } = options || {};
  const cookies = new Cookies();
  try {
    // Execute 'fetch' with settled settings
    const res = await fetch(`${host}/${path}`, {
      ...restOptions,
      method,
      ...(body && { body: JSON.stringify(body) }),
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        authorization: cookies.get('userData')?.token || {},
      },
    });

    if (!res.ok) {
      // If an error occurs because the connection is not established, return 'idPersisted' in case to be a stored mutation.
      throw res.statusText;
    }

    // In normal and general fetch,return the response data.
    const response = await res.json();
    return response;
  } catch (err) {
    throw err;
  }
};

export const API = {
  GET: dispatchFetch('GET'),
  PUT: dispatchFetch('PUT'),
  POST: dispatchFetch('POST'),
  DELETE: dispatchFetch('DELETE'),
};
