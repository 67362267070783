import {
  formatSucursal,
  formatNumero,
  formatDate,
  formatPrecio,
} from '../../hooks/useFormat';
import './PrintOrder.css';
import '../../../css/Tablas.css';
import { PrintGridOrder } from '../printGridPurchase/PrintGridOrder';
import { useGlobalStore } from '../../../../Hooks/useGlobalStore';
import { ResumeCardChild } from '../resumeCard/ResumeCardChild';
import { useCopyCart } from '../../hooks/useCopyCart';
import { useEffect, useState } from 'react';
import { useShopingCart } from '../../hooks/useShopingCart';

export const PrintOrder = ({ pedido }) => {
  const monto_con_iva = pedido.total;
  const monto_neto = pedido.total_sin_iva;
  const precio_iva = pedido.precio_iva;
  const porc_descuento = pedido.porc_descuento;
  const descuento = pedido.monto_descuento;
  const { getTotalItems } = useShopingCart();

  const getCurrencySymbol = (moneda) => (moneda === 0 ? 'ARS$ ' : 'US$ ');

  const safeFormatPrecio = (precio, cantidad) => {
    const safePrecio = isNaN(parseFloat(precio)) ? 0 : parseFloat(precio);
    const safeCantidad = isNaN(parseFloat(cantidad)) ? 0 : parseFloat(cantidad);
    return formatPrecio(safePrecio, safeCantidad);
  };

  const { data: isMobile } = useGlobalStore('isMobile');

  const { duplicateOrder } = useCopyCart();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (getTotalItems() > 0) {
      setIsButtonDisabled(true);
    }
  }, [getTotalItems]);

  const handleDuplicateOrder = () => {
    duplicateOrder(pedido);
    setIsButtonDisabled(true);
  };
  const tipoComp = pedido.id_tipo_comprobante;
  localStorage.setItem('tilde', tipoComp);
  let descCompleta = 'Presupuesto';
  if (tipoComp === 1) {
    descCompleta = 'Pedido';
  }

  return (
    <div className="print-order">
      <div className="headerPrint">
        <p className="fechaPed">{formatDate(pedido.fecha)}</p>
        <p>{formatSucursal(pedido.sucursal_mg || '0')}</p>
        <p>- {formatNumero(pedido.numero_mg || '0')}</p>
      </div>
      <p className="notaPrint">Documento no válido como factura</p>
      <p className="denominacion">
        {pedido.Cliente ? pedido.Cliente.codigo_mg : 'Cliente no encontrado'}
        {' - '}
        {pedido.Cliente
          ? pedido.Cliente.denominacion
          : 'Denominacion no encontrada'}
      </p>
      <div className="bodyPrint">
        {!isMobile ? (
          <table className="tablaHistorial tabla">
            <thead className="tabla__encabezado encabezadoPrintOrder">
              <tr className="fontTablaModal">
                <th className="tabla__titulo denomTablaModal">Descripción</th>
                <th className="tabla__titulo tabla__num tabla__media widthTablaModal">
                  Precio
                </th>
                <th className="tabla__titulo tabla__num tabla__media widthTablaModal">
                  Cantidad
                </th>
                <th className="tabla__titulo tabla__num tabla__media widthTablaModalDesc">
                  Descuento
                </th>
                <th className="tabla__titulo tabla__num tabla__media widthTablaModal">
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              {pedido.Comprobantes_items.map((item) => (
                <tr key={item.id} className="renglon-posicion">
                  <td className="tabla__contenido denomTablaModal fontTablaModal">
                    {item.Articulos}
                  </td>
                  <td className="tabla__contenido tabla__num fontTablaModal widthTablaModal">
                    {getCurrencySymbol(pedido.moneda)}
                    {item.precio}
                  </td>

                  <td className="tabla__contenido tabla__num fontTablaModal widthTablaModal">
                    {item.cantidad}
                  </td>
                  <td className="tabla__contenido tabla__num fontTablaModal widthTablaModalDesc">
                    {item.porc_desc}
                  </td>
                  <td className="tabla__contenido tabla__num tabla__media widthTablaModal">
                    {getCurrencySymbol(pedido.moneda)}
                    {safeFormatPrecio(item.precio, item.cantidad)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <PrintGridOrder
            items={pedido.Comprobantes_items}
            getCurrencySymbol={getCurrencySymbol}
            formatPrecio={safeFormatPrecio}
            moneda={pedido.moneda}
          />
        )}

        <article className="contenedorCardTotal stickyContenedor">
          <ResumeCardChild
            monto_con_iva={monto_con_iva}
            monto_neto={monto_neto}
            precio_iva={precio_iva}
            porc_descuento={porc_descuento}
            descuento={descuento}
            pedido={pedido}
          />
          <button
            onClick={handleDuplicateOrder}
            // className="btn btn-primary"
            className={`${isButtonDisabled ? 'btn-disabled' : 'btn-enabled'}`}
            disabled={isButtonDisabled}
          >
            Repetir {descCompleta}
          </button>
        </article>
      </div>
      <p className="printLeyenda">Gracias por su compra.</p>
    </div>
  );
};
