import React, { useContext, useEffect, useState } from 'react';
import { API_PRODUCTS_BUY } from './ApiRoutes';
import { UserContext } from '../../auth/context/UserContext';
import Swal from 'sweetalert2';
import { useShopingCart } from '../hooks/useShopingCart';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { useClient } from '../../catalogo/hooks/useClient';

export const SendBuy = ({ pedido }) => {
  const { dataUser } = useContext(UserContext);
  const { token } = dataUser;
  const { clearShopingCart, getTotal, getExeds, getItems } = useShopingCart();
  const [isLoading, setIsLoading] = useState(false);
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  //  const opDefault = cookies.get('botonCompra');

  const getNombreComp = () => {
    const codComp = localStorage.getItem('tilde'); // Leer el valor actualizado de localStorage
    if (codComp === '1') {
      return 'Pedido';
    } else if (codComp === '2') {
      return 'Presupuesto';
    }
    return ''; // Valor por defecto si no se encuentra el valor en localStorage
  };
  const { data: dataClient } = useClient();
  const handleSendBuy = (comprobante) => {
    //aqui armamos el objeto pedido

    const { id: clientId } = dataClient || {};
    const items = getItems().filter((item) => item.cantidad > 0);
    //ARMADO DE PEDIDO
    const productos = items.map(
      ({ id, cantidad, precios, moneda, porc_descuento }) => {
        precios.descuento = porc_descuento;

        return {
          id: id,
          cantidad: cantidad,
          precio: precios,
          moneda: moneda,
          descuento: porc_descuento,
        };
      }
    );

    const codComp = localStorage.getItem('tilde');
    const pedido = [
      {
        clientId,
        user: dataUser.id,
        products: productos,
        codigo_comprobante: codComp,
      },
    ];

    const nombreComp = getNombreComp();

    setIsLoading(true);
    cookies.set('botonCompra', comprobante);

    fetch(API_PRODUCTS_BUY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(pedido),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Error al enviar la compra');
      })
      .then((data) => {
        if (data.status === 200) {
          clearShopingCart();
          Swal.fire({
            title: `${nombreComp} enviado con éxito`,
            text: data.message,
            icon: 'success',
          });

          navigate('/catalog/catalogo');
        } else {
          Swal.fire({
            title: 'Error al enviar la compra',
            text: data.errors.detail,
            icon: 'error',
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error al enviar la compra:', error);
        Swal.fire({
          title: 'Error al enviar la compra',
          text: 'Ocurrió un error al enviar la compra. Por favor, inténtalo de nuevo más tarde.',
          icon: 'error',
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <button
        className={`btnTotStyle ${getExeds() ? 'btnTotStyleSecondary' : 'btnTotStylePrimary'}`}
        type="button"
        onClick={() => handleSendBuy('pedido')}
        disabled={getTotal() === 0 || isLoading || getExeds()}
      >
        {isLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <span>{getExeds() ? 'Descuento Excedido' : 'Enviar'}</span>
        )}
      </button>
    </>
  );
};
