import { Link } from 'react-router-dom';
import '../css/Nico.css';
import '../css/Styles.css';
import React, { useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../auth/context/UserContext';
//import { useGlobalStore } from '../../Hooks/useGlobalStore';
import jwt_decode from 'jwt-decode';

//import { SHOPPING_CART } from '../../helpers/features';

import { usePermissions } from '../../Hooks/usePermissions';

export const Sidebar = () => {
  const { dataUser } = useContext(UserContext);
  const userData = dataUser;
  const { token } = dataUser;
  const headers = new Headers();
  headers.set('authorization', token);
  //const { data } = useGlobalStore('Shop');

  const decodedToken = jwt_decode(token);

  const logo = decodedToken.logo;

  const { enableFeature } = usePermissions();

  useEffect(() => {
    const menu = document.querySelector('.menu-content');
    const menuItems = document.querySelectorAll('.submenu-item');
    const subMenuTitles = document.querySelectorAll('.submenu .menu-title');

    menuItems.forEach((item, index) => {
      item.addEventListener('click', () => {
        menu.classList.add('submenu-active');
        item.classList.add('show-submenu');
        menuItems.forEach((item2, index2) => {
          if (index !== index2) {
            item2.classList.remove('show-submenu');
          }
        });
      });
    });

    subMenuTitles.forEach((title) => {
      title.addEventListener('click', () => {
        menu.classList.remove('submenu-active');
      });
    });
  }, [userData]);

  return (
    <>
      <input type="checkbox" id="burger__toggle" />
      <label htmlFor="burger__toggle" className="burger">
        {/* <i className="fa-solid fa-bars"></i> id="sidebar-close" */}
      </label>
      <nav className="sidebar">
        <div className="sidebar-logoycruz">
          <Link className="link-logo-sidebar" to="/catalog/catalogo">
            <img className="imagen_logo" src={logo} />
          </Link>
          <label htmlFor="burger__toggle" className="cruzsidebar">
            <FontAwesomeIcon icon={faXmark} width={24} height={24} />
          </label>
        </div>
        <div className="menu-content">
          <ul className="menu-items">
            <li className="item">
              <div className="submenu-item">
                <span>Productos</span>
                <i className="fa-solid fa-chevron-right" />
              </div>

              <ul className="menu-items submenu">
                <div className="menu-title">
                  <i className="fa-solid fa-chevron-left" />
                  Atrás
                </div>
                <li className="item">
                  <Link to="/catalog/catalogo">Catalogo</Link>
                </li>
                {/* <li className="item">
                  <Link to="/catalog/catalogcuadricula">Cuadricula</Link>
                </li> */}
              </ul>
            </li>
            <li className="item">
              <div className="submenu-item">
                <span>Administrador</span>
                <i className="fa-solid fa-chevron-right" />
              </div>

              <ul className="menu-items submenu">
                <div className="menu-title">
                  <i className="fa-solid fa-chevron-left" />
                  Atrás
                </div>
                <li className="item">
                  {userData.roleId === 1 && (
                    <Link to="/security/nuevosusuarios">Nuevo usuario</Link>
                  )}
                </li>
                <li className="item">
                  {userData.roleId === 1 && (
                    <Link to="/security/Perfiles">Perfiles</Link>
                  )}
                </li>
                <li className="item">
                  {userData.roleId === 1 && (
                    <Link to="/security/gestionperfiles">Asignar Perfiles</Link>
                  )}
                </li>
              </ul>
            </li>
            {/* {enableFeature(SHOPPING_CART) && (
              <li className="item">
                <div className="submenu-item">
                  <span>Mis Pedidos</span>
                  <i className="fa-solid fa-chevron-right" />
                </div>
                <ul className="menu-items submenu">
                  <div className="menu-title">
                    <i className="fa-solid fa-chevron-left" />
                    Atrás
                  </div>
                  <li className="item">
                    <Link to="/catalog/purchasesConfirm">Historial</Link>
                  </li>
                  {/* Esto esta asi para ocultar flecha de Mis pedidos /}
                  <li className="item">
                    <Link to="/catalog/purchasesConfirm"></Link>
                  </li>
                  <li className="item">
                    <Link to="/catalog/purchasesConfirm"></Link>
                  </li>
                </ul>
              </li>
            )} */}
            <li className="item">
              <div className="submenu-item">
                <span>Mis Comprobantes</span>
                <i className="fa-solid fa-chevron-right" />
              </div>

              <ul className="menu-items submenu">
                <div className="menu-title">
                  <i className="fa-solid fa-chevron-left" />
                  Atrás
                </div>
                <li className="item">
                  <Link to="/catalog/purchasesConfirm">Historial</Link>
                </li>

                <li className="item">
                  <Link to="/catalog/purchasesConfirm"></Link>
                </li>
                <li className="item">
                  <Link to="/catalog/purchasesConfirm"></Link>
                </li>
              </ul>
            </li>
            <ul className="menu-items">
              <li className="item">
                <Link to="/contact">Contacto</Link>
              </li>
            </ul>
          </ul>
        </div>
      </nav>
    </>
  );
};
