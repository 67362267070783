import { Route, Routes } from 'react-router-dom';
import { Permisos, PermisosRoles } from '../';

import { UsuariosRoles } from '../UsuariosRoles';
import EditPerfil from '../EditPerfil';
import NuevosUsuarios from '../pages/NuevosUsuariosPage';

import { GestionPerfiles } from '../GestionPerfiles';

import { AdministrarCuit } from '../AdministrarCuit';
import { Perfiles } from '../../perfiles/pages/Perfiles';

export const SecurityRoutes = () => (
  <Routes>
    <Route path="permisos" element={<Permisos titulo="Permisos" />} />
    <Route
      path="permisosroles"
      element={<PermisosRoles titulo="Permisos por Roles" />}
    />
    <Route path="Perfiles" element={<Perfiles titulo="Perfiles" />} />
    <Route
      path="admcuit"
      element={<AdministrarCuit titulo="Administrar Empresas" />}
    />
    <Route
      path="usuariosroles"
      element={<UsuariosRoles titulo="Usuarios por Roles" />}
    />
    <Route
      path="nuevosusuarios"
      element={<NuevosUsuarios titulo="Nuevo Usuario" />}
    />
    <Route
      path="editperfil"
      element={<EditPerfil titulo="Edición de Perfil" />}
    />
    <Route
      path="gestionperfiles"
      element={<GestionPerfiles titulo="Gestión de Perfiles" />}
    />
  </Routes>
);
